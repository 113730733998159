module.exports = {
    name:'English',
    home:{
        zc: 'Crowdfunding',
        yy: 'Appointment',
        zcc: 'Asset',
        wd: 'Mine',
        q: 'Period',
        bqzced: 'Crowdfunding quota for this period',
        cyed: 'Participation quota',
        qxft: 'Cancel reinvestment',
        success: 'Successful',
        failed: 'Failed',
        removal: 'Removal',
        loading: 'Loading',
        zfmm: 'Payment password',
        srmm: 'Enter a 6-digit password',
        qd: 'Determine',
        qx:'Cancel',
        wdtd: 'My Team',
        fxhtg: 'Share Promotion',
        lange: 'Language',
        tc: 'Exit',
        tcdl: 'Log out of login',
        qrtcdl: 'Confirm logging out',
        mm: 'Password',
        ye: "Balance",
        mx: "Details",
        tb: "Withdraw",
        cz: "Deposit",
        syjl: "Revenue reward",
        jtsy: "Interest rewards",
        dsjl: "Dynamic rewards",
        vipjl: "VIP Rewards",
        pjjl: 'Level Reward',
        lyyh: 'Source User',
        tj: "Statistics",
        tbp: 'Please enter the quantity',
        khdstt: 'STT can be obtained',
        qrtq: 'Confirm extraction',
        tbtips: 'Automatically convert quantity after deducting handling fees based on exchange rate',
        czp: 'Please enter the quantity',
        cztips: 'The payment quantity is automatically converted based on the exchange rate, and the recorded quantity is affected by the exchange rate, subject to the actual amount',
        ljcz: 'Accumulated Deposit',
        ljtx: 'Accumulated withdrawal',
        ljyy: 'Accumulated appointments',
        yysy: 'Appointment remaining',
        bjzcz: 'Principal (in crowdfunding)',
        lxzcz: 'Interest (in crowdfunding)',
        bjyjs: 'Principal (settled)',
        lxyjs: 'Interest (settled)',
        yysl: 'Appointment quantity',
        qb: 'All',
        yyzd: 'Booking a minimum of ',
        zd: 'and a maximum of',
        start:'The reservation quantity starts at',
        dzs: 'integers',
        ddjl: 'Order record',
        noMore: "There's nothing more",
        ddbh: 'Order number',
        yysj: 'Appointment time',
        zjjh: 'Recent crowdfunding',
        sssl: 'Remaining quantity',
        gz: 'Rule',
        gz0:'which is an integer multiple of',
        gz1:'The reservation order is irrevocable.',
        gz2: 'Appointment orders may be divided into multiple entries into the game.',
        gz3: 'Orders with priority values will enter the game first.',
        lxjl: 'Interest reward',
        dthl: 'Dynamic rewards',
        tx: 'Withdrawal',
        txth: 'Withdrawal refund',
        thbj: 'Refund principal',
        jslx: 'Settlement interest',
        jsbj: 'Settle principal',
        jjff: 'Bonus distribution',
        sysljl: 'Remaining quantity reward',
        qsrje: 'Please enter the amount',
        zf:'Payment',
        getSTT:' STT must be greater than 0',
        get2number:'Please enter decimals up to 2 places',
        noticeList:'Notice List',
        noticeDetail:'Notice Details',
        noContent:'There is currently no content available',
        zcjl: 'Crowdfunding Record',
        zcSatuts1: 'Aggregation successful',
        zcSatuts2: 'In the middle of a comeback',
        zcSatuts3: 'Appointment for retrieval',
        zcSatuts4: 'Returned',
        zcSatuts5: 'Settled',
        qc:'Take out',
        yyxq:'Appointment details',
        payMsg3:'Lack of balance',
        payMsg4:'Insufficient token balance',
        payMsg5:'There is no contract',
        payMsg6:"Wallets don't exist",
        payMsg7:'User cancel operation',
        secQuestion:'Explain',
        secQuestion1:'1.The price of SEC/USDT is affected by the quantity of SEC and USDT in the bottom pool',
        secQuestion2:'2.The assets settled through crowdfunding will enter the bottom pool as SEC-USDT bidirectional assets',
        secQuestion3:'3.When redeemed, the corresponding SEC and USDT will be revoked',
        secQuestion4:'4.When transferring funds, a certain amount of SEC will be deducted and destroyed from the bottom pool',
        setType1: 'Withdraw principal+withdraw interest',
        setType2: 'reinvestment principal+reinvestment interest',
        setType3: 'reinvest principal+withdraw interest',
        jrfx: 'Released Today',
        jrzg: "Today's highest",
        dc: 'Exchange out',
        zz: 'Transfer',
        dcp: "Please enter the withdrawal quantity",
        dqjg: "Current price",
        yjhd: "Expected acquisition",
        qrdc: "Confirm withdrawal",
        zzp: "Please enter the transfer quantity",
        dfdz: "Please enter the recipient's address",
        qrzc: 'Confirm transfer out',
        bdmx: 'Change Details',
        zzkc: 'Transfer deduction',
        zzrz: 'Transfer to account',
        dr: 'Exchange in',
        kpj: 'Opening price',
        spj: 'Closing price',
        zdj: 'Lowest price',
        zgj: 'Highest price',
    },
    login:{
        yqm: 'Invitation code',
        yqmP: 'Please enter the invitation code',
        ljdl: 'Login Now',
        statterEnv: 'Please open it using the statter wallet browser',
        chainNot: 'Please use STT chain login',
        maintenance: 'System maintenance in progress',
        yyqh: 'Language switching',
        bc: 'Save'
    },
    team:{
        yjsl: 'Performance quantity',
        zjhy: 'Direct recommended members',
        pthy: 'Ordinary',
        swhy: 'Business vip',
        top:'Highest level',
        achievement:'Achievement',
    },
    share:{
        uid: 'Your Invitation code',
        zflj: 'Click to copy the promotion link',
        tips: 'This invitation code is used to promote the establishment of recommendation relationships when users register.',
    }
}
