import En from '../lang/en'  // 英语语言包
import Tc from '../lang/tc'  // 英语语言包
const messages = {
    'en-US': {...En },
    'zh-HK': {...Tc },

}
// { label: '中文简体', value: 'zh-CN' },
// { label: '英文', value: 'en-US' },
// { label: '韩文', value: 'ko-KR' },
// { label: '日文', value: 'ja-JP' },
// { label: '中文繁體', value: 'zh-Hk' },
// 使用
//  <div class=""> {{ $t('msg.test') }}</div>


//1.组件的模板中使用 $t()
//2.组件的js使用 i18n.t
//3.非组件中使用i18n.global.t
//4.切换组件还是要
import { createI18n } from 'vue-i18n'
const i18n = createI18n({
    // 使用 Composition API 模式，则需要将其设置为false
    legacy: false,
    // 全局注入 $t 函数
    globalInjection: true,
    locale:sessionStorage.getItem('lange') || 'zh-HK',
    messages,
    // 防止使用v-html=“$t(“”)”的xss警告
    warnHtmlMessage: false
})
export default i18n
