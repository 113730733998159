<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script setup>


</script>

<style>
/*数字字体*/
@font-face {
  font-family: 'DIN-Bold';
  src: url('~@/assets/fonts/DIN-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'DIN-Medium';
  src: url('~@/assets/fonts/DIN-Medium.otf') format('opentype');
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}
html,body{
  width: 100%;
  margin: 0;
  background: #14181A;
}
/*.van-toast--middle{*/
/*  z-index: 2003!important;*/
/*}*/
</style>
