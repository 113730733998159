module.exports = {
    name:'中文繁體',
    home:{
        zc:'眾籌',
        yy:'預約',
        zcc:'資產',
        wd:'我的',
        q:'期',
        bqzced:'本期眾籌額度',
        cyed:'參與額度',
        qxft:'取消複投',
        success:'成功',
        failed:'失敗',
        removal: '預約取出',
        loading:'加載中',
        zfmm:'支付密碼',
        srmm:'輸入6位數密碼',
        qd:'確定',
        qx:'取消',
        wdtd:'我的團隊',
        fxhtg:'分享和推廣',
        lange:'語言',
        tcdl:'登出',
        qrtcdl:'確認登出',
        tc:'退出',
        mm:'密碼',
        ye:"餘額",
        mx:"明細",
        tb:"提幣",
        cz:"充值",
        syjl:"收益獎勵",
        jtsy:"利息獎勵",
        dsjl:"動態獎勵",
        vipjl:"VIP獎勵",
        pjjl:'平級獎勵',
        lyyh:'來源用戶',
        tj:"統計",
        tbp:'請輸入提幣數量',
        khdstt:'可獲得STT',
        qrtq:'確認選取',
        tbtips:'獲取數量扣除手續費後根據匯率自動換算',
        czp:'請輸入USDT充值數量',
        cztips:'支付數量根據匯率自動換算，上帳數量受匯率影響，以實際為准',
        ljcz:'累計充值',
        ljtx:'累計提現',
        ljyy:'累計預約',
        yysy:'預約剩餘',
        bjzcz:'本金（眾籌中）',
        lxzcz:'利息（眾籌中）',
        bjyjs:'本金（已結算）',
        lxyjs:'利息（已結算）',
        yysl:'預約數量',
        qb:'全部',
        yyzd:'預約最低',
        zd:'最大',
        start:'起',
        dzs:'的整數',
        ddjl:'訂單記錄',
        noMore:'沒有更多了',
        ddbh:'訂單編號',
        yysj:'預約時間',
        zjjh:'最近眾籌',
        sssl:'剩餘數量',
        gz:'規則',
        gz1:'的整數倍，預約訂單不可撤銷。',
        gz2:'預約訂單可能分成多次進入到遊戲中。',
        gz3:'具有優先值的訂單優先進入到遊戲中。',
        lxjl:'利息獎勵',
        dthl:'動態獎勵',
        tx:'提現',
        txth:'提現退還',
        thbj:'退還本金',
        jslx:'結算利息',
        jsbj:'結算本金',
        jjff:'獎金發放',
        sysljl:'剩餘數量獎勵',
        qsrje:'請輸入金額',
        zf:'支付',
        getSTT:'STT必須大於0',
        get2number:'請輸入2比特以內的小數',
        noticeList:'公告清單',
        noticeDetail:'公告詳情',
        noContent:'暫無內容',
        zcjl:'眾籌記錄',
        zcSatuts1:'聚合成功',
        zcSatuts2:'複投中',
        zcSatuts3:'預約取出',
        zcSatuts4:'已退還',
        zcSatuts5:'已結算',
        qc:'取出',
        yyxq:'預約詳情',
        payMsg3:'餘額不足',
        payMsg4:'代幣餘額不足',
        payMsg5:'沒有契约',
        payMsg6:'錢包不存在',
        payMsg7:'用戶取消操作',
        secQuestion:'說明',
        secQuestion1:'1.SEC/USDT價格受底池SEC和USDT數量影響.',
        secQuestion2:'2.眾籌結算的資產以SEC-USDT雙向資產進入到底池.',
        secQuestion3:'3.兌出時，將撤銷相應的SEC和USDT.',
        secQuestion4:'4.轉帳時，將扣除一定數量的SEC從底池中銷毀.',
        setType1:'取出本金+取出利息',
        setType2:'複投本金+複投利息',
        setType3:'複投本金+取出利息',
        jrfx:'今日發行',
        jrzg:'今日最高',
        dc:'兌出',
        zz:'轉帳',
        dcp:'請輸入兌出數量',
        dqjg:'當前價格',
        yjhd:'預計獲得',
        qrdc:'確認兌出',
        zzp:'請輸入轉出數量',
        dfdz:'請輸入對方地址',
        qrzc:'確認轉出',
        bdmx:'變動明細',
        zzkc:'轉帳扣除',
        zzrz:'轉帳入帳',
        dr:'兌入',
        kpj:'開盤價',
        spj:'收盤價',
        zdj:'最低價',
        zgj:'最高價',
    },
    login:{
        yqm:'邀請碼',
        yqmP:'請輸入邀請碼',
        ljdl:'立即登入',
        statterEnv:'請使用statter錢包瀏覽器打開',
        chainNot:'請使用STT鏈登入',
        maintenance:'系統維護中',
        yyqh:'語言切換',
        bc:'保存',
    },
    team:{
        yjsl:'業績數量',
        zjhy:'直薦會員',
        pthy:'普通會員',
        swhy:'商務會員',
        top:'團隊最高等級',
        achievement:'團隊業績',
    },
    share:{
        uid:'您的邀請碼',
        zflj:'點擊複製推廣連結',
        tips:'該邀請碼用於推廣用戶註冊時，建立推薦關係',

    }

}
